<template>
  <b-card>
    <div class="mb-2 d-flex justify-content-end">
      <!-- Table Top -->
      <b-form-input
        v-model="searchQuery"
        class="d-inline-block mr-1"
        :placeholder="$t('search')"
        @input="priceListUpdate()"
      />
      <b-button variant="success" @click="updatePrices()">{{
        $t("update")
      }}</b-button>
    </div>
    <div>
      <div v-click-outside="outside"></div>

      <b-form
        ref="form"
        :style="{ height: trHeight }"
        class="repeater-form"
        @submit.prevent="repeateAgain"
      >
        <b-table
          ref="refIngredienttListTable"
          :items="prices"
          striped
          :small="false"
          :fields="fields"
          responsive
          class="editable-table"
          primary-key="nkey"
          show-empty
          :empty-text="$t('noRecords')"
          :per-page="15"
          :current-page="currentPage"
          :sort-compare="sortCompare"
        >
          <template #cell(price)="data">
            <div class="editable" @click="selectColumn(data)">
              <span v-if="!selectedColumn(data)" class="editable">
                {{
                  parseFloat(data.item.price)
                    .toFixed(ingredientPrice)
                    .replace(".", ",")
                }}
              </span>

              <b-form-input
                v-show="selectedColumn(data)"
                :id="data.item.ingredientname + data.index + 'price'"
                :data-id="'nutrient-' + data.field.key + data.index"
                :value="
                  parseFloat(data.item.price)
                    .toFixed(ingredientPrice)
                    .replace(',', '.')
                "
                type="number"
                :options="{
                  numeral: true,
                }"
                class="editable text-right"
                @input="changedItem(data.item)"
                @change="(v) => (data.item.price = parseFloat(v))"
              />
            </div>
          </template>
        </b-table>

        <!-- Row Loop -->
      </b-form>
      <div class="d-flex justify-content-end mt-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="15"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BForm,
  BFormInput,
  BButton,
  BCard,
  BPagination,
  BTable,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import { getUserData } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VueI18n from "@/libs/i18n";
import store from "@/store";

export default {
  components: {
    BForm,
    // BRow,
    // BCol,
    BButton,
    // BFormGroup,
    BPagination,
    BFormInput,
    BCard,
    BTable,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  beforeRouteEnter(to, from, next) {
    if (to.name == "ingredientsPrices") {
      to.meta.breadcrumb = [];
      to.meta.breadcrumb.push({
        to: "/",
        text: VueI18n.t("ingredients"),
        active: true,
      });

      to.meta.breadcrumb.push({
        text: VueI18n.t("ingredientPrices"),
        active: true,
      });
    }
    next();
  },
  data() {
    return {
      currentPage: 1,
      getUserData,
      prices: [],
      allPrices: [],
      changedItems: [],
      searchQuery: "",
      editColumn: { cell: "", index: "" },
      fields: [
        {
          key: "kod",
          label: VueI18n.t("code"),
          sortable: true,
          filterable: true,
        },
        {
          key: "ingredientname",
          label: VueI18n.t("ingredient"),
          sortable: true,
          filterable: true,
        },

        {
          key: "price",
          label: VueI18n.t("price"),
          sortable: false,
          filterable: true,
          editable: true,
          tdClass: "table-max-width text-right mw-300",
          thClass: "table-max-width text-right mw-300",
        },
      ],
    };
  },
  computed: {
    rows() {
      return this.prices.length;
    },
    ingredientPrice() {
      return getUserData().ingredientdecimal;
    },
  },

  async mounted() {
    const { data } = await this.$http.get("/Ingredients/GetIngredientPrices/", {
      headers: {
        plantid: this.$store.state.app.selectedPlantId,
      },
    });
    this.prices = data.resultdata.sort((a, b) => this.sortCompare(a, b, "kod"));
    this.allPrices = this.prices;
    const editebleColumns = this.fields
      .filter((x) => x.editable)
      .map((x) => x.key);

    var keys = [];
    this.$nextTick(() =>
      document.querySelectorAll("input.editable").forEach((x) => {
        x.addEventListener("keydown", function (e) {
          if (e.which === 38 || e.which === 40) {
            e.preventDefault();
          }
        });
      })
    );

    document.addEventListener("keyup", (e) => {
      let backOrForward = 1;
      if (e.code === "ArrowUp") {
        backOrForward = -1;
      }
      if (
        e.code === "Enter" ||
        e.code === "NumpadEnter" ||
        e.code === "Tab" ||
        e.code === "ArrowDown" ||
        e.code === "ArrowUp" ||
        keys.length == 2
      ) {
        if (this.editColumn.cell != "") {
          var cellIndex =
            editebleColumns.indexOf(this.editColumn.cell) + backOrForward;
          this.editColumn.cell =
            editebleColumns[cellIndex % editebleColumns.length];
          if (this.editColumn.cell === editebleColumns[0] && keys.length != 2) {
            this.editColumn.index = this.editColumn.index + backOrForward;
          }

          this.focusSelectedInput(this.editColumn.cell, this.editColumn.index);
        }
      }
    });
    this.initTrHeight();
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    sortCompare(a, b, key) {
      if (key == "price") {
        return a[key] - b[key];
      }
      return a[key].localeCompare(b[key]);
    },
    selectedColumn(data) {
      return (
        this.editColumn.cell == data.field.key &&
        this.editColumn.index == data.index
      );
    },
    selectColumn(data) {
      this.editColumn.cell = data.field.key;
      this.editColumn.index = data.index;
      this.focusSelectedInput(data.field.key, data.index);
    },
    focusSelectedInput(key, index) {
      var input = document.querySelector(
        `input[data-id="nutrient-${key + index}"]`
      );
      if (input)
        window.setTimeout(() => {
          input.focus();
        }, 1);
    },
    outside(e) {
      var classLists = [...(e.target.classList ?? [])];
      if (e.target.parentElement) {
        classLists = [
          ...classLists,
          ...(e.target.parentElement.classList ?? []),
        ];
      }
      if (e.target.childElement) {
        classLists = [
          ...classLists,
          ...(e.target.childElement.classList ?? []),
        ];
      }

      if (!classLists.includes("editable")) {
        this.editColumn = { cell: "", index: "" };
      }
    },
    priceListUpdate() {
      this.prices = this.allPrices.filter((prices) =>
        prices.ingredientname
          .toLowerCase()
          .startsWith(this.searchQuery.toLowerCase())
      );
    },
    changedItem(item) {
      var index = this.changedItems.map((x) => x.id).indexOf(item.id);
      if (index == -1) this.changedItems.push(item);
      else this.changedItems.splice(index, 1, item);
    },
    async updatePrices() {
      var ikili = this.changedItems.map((x) => {
        return {
          id: x.id,
          deger: parseFloat(x.price),
        };
      });
      var result = await this.$http.put(
        `/Ingredients/UpdateIngredientPrices/${this.$store.state.app.selectedPlantId}`,
        JSON.stringify(ikili)
      );
      if (result.data.status == "OK")
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: VueI18n.t("successful"),
            icon: "CheckIcon",
            variant: "success",
            text: VueI18n.t("updated", { type: VueI18n.t("prices") }),
          },
        });
      else
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: VueI18n.t("unsuccessful"),
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: VueI18n.t("notUpdated", { type: VueI18n.t("prices") }),
          },
        });
    },

    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
.editable {
  cursor: pointer;
}
input.editable {
  text-align: right;
}
.text-right {
  text-align: right;
}
</style>
